var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('LabelTextField',{attrs:{"label":"Wyszukaj pacjenta","value":_vm.internalSearch,"searchIcon":true,"clearable":"","hide-details":""},on:{"update:value":function($event){_vm.internalSearch=$event}}}),_c('DefaultButton',{staticClass:"mr-4 ml-0 px-0",style:({ 
        borderColor: ("var(" + (_vm.filtered ? '--v-success-base' : '--v-primary-base') + ") !important"),
        color: ("var(" + (_vm.filtered ? '--v-success-base' : '--v-primary-base') + ") !important"),
        marginBottom: '14px !important' 
      }),on:{"click":function($event){$event.preventDefault();_vm.filterDialogVisible = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20px !important","color":_vm.filtered ? 'success' : 'primary'}},[_vm._v("mdi-filter")]),_vm._v("Filtry ")],1)],1),_c('v-layout',{attrs:{"column":""}},[_c('DataTable',{attrs:{"headers":_vm.headers,"pagination":_vm.internalPagination,"objects":_vm.patients,"totalItems":_vm.totalItems,"hideToolBar":"","stripped":"","customHeight":_vm.$vuetify.breakpoint.smAndUp ? ("min(" + ((_vm.patients.length + 1) * 49) + "px, calc(100vh - 286px)) !important") : undefined,"fixedHeader":_vm.$vuetify.breakpoint.smAndUp},on:{"update:pagination":function($event){_vm.internalPagination=$event}},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
      var header = ref.header;
      var objects = ref.objects;
return [_c(_vm.cellFactory(header, objects),{tag:"component",attrs:{"value":_vm.cellValueFactory(header, objects),"header":header,"item":objects.item,"additionalPadding":"16px"},on:{"clicked":function (x, y, doctor) { return _vm.handleCellClick(header, objects.item, doctor); }}})]}}])}),_c('v-layout',{staticClass:"ma-4"},[_c('v-spacer'),_c('Paginator',{attrs:{"pagination":_vm.internalPagination,"total":_vm.totalItems},on:{"update:pagination":function($event){_vm.internalPagination=$event}}})],1)],1),(_vm.filterDialogVisible)?_c('PatientListFilterDialog',{attrs:{"value":_vm.filterDialogVisible,"pagination":_vm.internalPagination},on:{"update:value":function($event){_vm.filterDialogVisible=$event},"update:pagination":function($event){_vm.internalPagination=$event}}}):_vm._e(),(_vm.cardDialogVisible)?_c('PatientCardDialog',{attrs:{"value":_vm.cardDialogVisible,"patient":_vm.cardDialogPatient,"owner":_vm.cardDialogOwner},on:{"update:value":function($event){_vm.cardDialogVisible=$event},"success":_vm.handleSuccess,"error":_vm.handleError}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }