<template>
  <v-layout column>
    <v-layout align-center>
      <LabelTextField label="Wyszukaj pacjenta" :value.sync="internalSearch" :searchIcon="true" clearable hide-details>
      </LabelTextField>
      <DefaultButton class="mr-4 ml-0 px-0"
        :style="{ 
          borderColor: `var(${filtered ? '--v-success-base' : '--v-primary-base'}) !important`,
          color: `var(${filtered ? '--v-success-base' : '--v-primary-base'}) !important`,
          marginBottom: '14px !important' 
        }"
        @click.prevent="filterDialogVisible = true">
        <v-icon size="20px !important" class="mr-1" :color="filtered ? 'success' : 'primary'">mdi-filter</v-icon>Filtry
      </DefaultButton>
    </v-layout>
    <v-layout column>
      <DataTable :headers="headers" :pagination.sync="internalPagination" :objects="patients" :totalItems="totalItems"
        hideToolBar stripped
        :customHeight="$vuetify.breakpoint.smAndUp ? `min(${(patients.length + 1) * 49}px, calc(100vh - 286px)) !important` : undefined"
        :fixedHeader="$vuetify.breakpoint.smAndUp">
        <template v-slot:cell="{ header, objects }">
          <component :is="cellFactory(header, objects)" :value="cellValueFactory(header, objects)" :header="header"
            :item="objects.item" additionalPadding="16px" @clicked="
              (x, y, doctor) => handleCellClick(header, objects.item, doctor)
            "></component>
        </template>
      </DataTable>
      <v-layout class="ma-4">
        <v-spacer></v-spacer>
        <Paginator :pagination.sync="internalPagination" :total="totalItems"></Paginator>
      </v-layout>
    </v-layout>
    <PatientListFilterDialog v-if="filterDialogVisible" :value.sync="filterDialogVisible"
      :pagination.sync="internalPagination"></PatientListFilterDialog>
    <PatientCardDialog v-if="cardDialogVisible" :value.sync="cardDialogVisible" :patient="cardDialogPatient"
      :owner="cardDialogOwner" @success="handleSuccess" @error="handleError"></PatientCardDialog>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import moment from "moment";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    patients: {
      type: Array,
    },
    pagination: {
      type: Object,
    },
    totalItems: {
      type: Number,
    },
    search: {
      type: String,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Imię i nazwisko",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Ostatnia wizyta",
          align: "left",
          sortable: true,
          value: "last_appointment",
        },
        {
          text: "Adres",
          align: "left",
          sortable: true,
          value: "compact_address",
        },
        {
          text: "Telefon",
          align: "left",
          sortable: false,
          value: "phone",
        },
        {
          text: "Terapeuta",
          align: "left",
          sortable: false,
          value: "main_doctors",
        },
        {
          text: "Wypełniona karta",
          align: "left",
          sortable: false,
          value: "any_unfinished",
        },
      ],
      filterDialogVisible: false,
      cardDialogVisible: false,
      cardDialogPatient: undefined,
      cardDialogOwner: undefined,
    };
  },
  computed: {
    internalPagination: {
      get() {
        return this.pagination;
      },
      set(value) {
        this.$emit("update:pagination", value);
      },
    },
    internalSearch: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("update:search", value);
      },
    },
    filtered() {
      return (
        this.internalPagination.unfinished ||
        this.internalPagination.consent !== undefined ||
        this.internalPagination.address !== undefined ||
        this.internalPagination.identity !== undefined ||
        this.internalPagination.last_appointment_date
      );
    },
  },
  components: {
    DataTable: () => import("@/components/tables/DataTable"),
    DefaultClickableCell: () =>
      import("@/components/tables/DefaultClickableCell"),
    IconCell: () => import("@/components/tables/IconCell"),
    LabelTextField: () => import("@/components/LabelTextField"),
    IconButton: () => import("@/components/buttons/IconButton"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    PatientListFilterDialog: () =>
      import("@/components/popups/PatientListFilterDialog"),
    OtherMainDoctorsCell: () =>
      import("@/components/tables/OtherMainDoctorsCell"),
    PatientCardDialog: () =>
      import("@/components/popups/patientcard/PatientCardDialog"),
    BooksyBadgeCell: () => import("@/components/tables/BooksyBadgeCell"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    Paginator: () => import("@/components/tables/Paginator"),
  },
  methods: {
    handleCellClick(header, item, doctor) {
      console.log(header, item);
      if (header.text === "Terapeuta" && doctor) {
        this.cardDialogOwner = doctor;
        this.cardDialogPatient = item;
        this.cardDialogVisible = true;
      } else {
        this.goToDetails(item);
      }
    },
    goToDetails(item) {
      localStorage.setItem("BACK", this.$route.query.state);
      this.$router.replace({
        name: "patient",
        query: {
          ...this.$route.query,
          state: "customer_details",
          id: item.id,
        },
      });
    },
    cellFactory(header) {
      switch (header.text) {
        case "Wypełniona karta":
          return "BooksyBadgeCell";
        case "Terapeuta":
          return "OtherMainDoctorsCell";
        default:
          return "DefaultClickableCell";
      }
    },
    cellValueFactory(header, objects) {
      switch (header.text) {
        case "Wypełniona karta":
          return objects.item[header.value] ? false : true;
        case "Ostatnia wizyta":
          return objects.item[header.value] ? moment(objects.item[header.value]).format("YYYY-MM-DD HH:mm") : "-";
        default:
          return objects.item[header.value] || "-";
      }
    },
  },
};
</script>